'use strict';


angular.module('halyk-config', [

    'platform'

])
    .constant('viewConfig', {
        ACCOUNT_TRANSACTIONS_SUMMARY_VISIBILITY: true,
        LARGE_SCREEN_WIDTH: 1200,
        SMARTFON_SCREEN_MAX_WIDTH: 527,
        EVENT_LOAD_MORE: 'cb.dashboard.scroll.end.event',
        PMT_FILTER_PERIOD_COUNT_DEFAULT: 30,
        STATIC_BANNER_BREAKPOINT: 1440,
        STATIC_BANNER_HREF: '/images/banner.jpg',
        CALENDAR_CELL_ENTRIES_COUNT: 2
    })
    .config(function (ebThemingProvider, layoutServiceProvider, guiSettingsProvider) {
        ebThemingProvider.setDefaultTheme('halyk');
        layoutServiceProvider.setLayout('halyk-layout');

        guiSettingsProvider.guiSettingsStorageItemsKeys = [
            'cui-dashboard.settings',
            'cui-layout.settings'
        ];

        guiSettingsProvider.oldSettingsNamespaceAdjustment = "halyk-";
    })
    .run(function ($window, $location, $rootScope) {

        // initialise google analytics
        if ($window.ga) {//
            $window.ga('create', 'UA-179039009-1', 'auto');

            // track pageview on state change
            $rootScope.$on('$stateChangeSuccess', function (event) {
                $window.ga('send', 'pageview', $location.path());
            });
            // inject google analytics on window.open
            window.open = function (openFunc) {
                return function (url) {
                    if (url) {
                        $window.ga('send', 'pageview', url);
                    }
                    return openFunc.call(window, url);
                };
            }(window.open);
        }
        //
        // chatCallappService.state = {
        //     firstName: "Nikoloz",
        //     lastName: "Shalikiani",
        //     colvirID: "0102.007808"
        // };
    });
